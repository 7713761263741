
import { users } from '@/api/services/user';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonButton, modalController, IonPage, IonText, IonCard } from "@ionic/vue";
import { defineComponent, inject, reactive} from "vue";
import { Emitter, EventType } from 'mitt';
export default defineComponent({

  name: "Modal",
  components: { IonContent, IonHeader,  IonItem, IonLabel, IonInput, IonButton, IonPage, IonText },
  setup() {
    const { isConnected, updateUserInfo, fetchUserInfos, deafultPassword, chk } = users();
    const emitter = inject<Emitter<Record<EventType, unknown>>>('emitter');
    const form = reactive({
        id: '',
        sano: '',
        email: '',
        password: ''
    });
    
    function closeModal() {
      modalController.dismiss();
    }
    function submit() {

    deafultPassword(form.id,form.sano, form.email)
    form.password = form.id;
    }
    return {
      form,
      closeModal,
      updateUserInfo,
      submit,
      isConnected,
      fetchUserInfos,
      deafultPassword,
      chk
    }
  }
});

